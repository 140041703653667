<template>
  <div class="body">
    <!-- <v-parallax
      height="200"
      src="https://lh3.googleusercontent.com/pw/AM-JKLWjXtkYIkVOPURKgNyqjmysjFdaMaZgTIWVAMDB2ivt-k2qOYocnGEepAuV-0NrEAXlEx64asUZrauy1nSD2_SVEXtoJzM-SFrHZz9So4RkqJyAcgDI-IVU9XNzis_2CKBLlgqZv0G9qV1RubnmWVzbww=w840-h473-no"
    ></v-parallax> -->
    <v-container class="px-md-12 py-8">
      <div class="text-h5">Activities <kbd>CCUB 4621</kbd></div>
      <br />

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Naqib: Br Halil Ibrahim (Section 114)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img
              contain
              src="https://lh3.googleusercontent.com/pw/AM-JKLVloSi6CFMi2gICqTyp4Kr68gVR5ZotuxF44RB-Omms3CCc_cXt0qgUJ1ACpZAKjgMxNn8AGe0bHeG-AbT-7J64meQPs_X1gMjZ4EIsOnxj4Q4aRmv1aJHoxUU1mg_T-j5nOJ9zOhs5U_OfSNluyXOHUw=w467-h933-no"
              max-height="300"
            ></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            City: Temerloh (Malaysia)</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div
              style="
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 56.25%;
                padding-bottom: 48px;
                box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
                margin-top: 1.6em;
                margin-bottom: 0.9em;
                overflow: hidden;
                border-radius: 8px;
                will-change: transform;
              "
            >
              <iframe
                loading="lazy"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  border: none;
                  padding: 0;
                  margin: 0;
                "
                src="https://www.canva.com/design/DAEvaU9vmK0/view?embed"
                allowfullscreen="allowfullscreen"
                allow="fullscreen"
              >
              </iframe>
            </div>
            <a
              href="https://www.canva.com/design/DAEvaU9vmK0/view?utm_content=DAEvaU9vmK0&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
              target="_blank"
              rel="noopener"
              >Usrah 4 City Temerloh</a
            >
            by MUHD FAREEZ IQMAL
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Role Play: Despair
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              style="
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 141.4286%;
                padding-bottom: 48px;
                box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
                margin-top: 1.6em;
                margin-bottom: 0.9em;
                overflow: hidden;
                border-radius: 8px;
                will-change: transform;
              "
            >
              <iframe
                loading="lazy"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  border: none;
                  padding: 0;
                  margin: 0;
                "
                src="https://www.canva.com/design/DAExYvifp-8/view?embed"
                allowfullscreen="allowfullscreen"
                allow="fullscreen"
              >
              </iframe>
            </div>
            <a
              href="https://www.canva.com/design/DAExYvifp-8/view?utm_content=DAExYvifp-8&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
              target="_blank"
              rel="noopener"
              >Despair - USRAH - Fareez</a
            >
            by MUHD FAREEZ IQMAL
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Activities4",
};
</script>

<style scoped>
</style>