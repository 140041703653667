<template>
  <div>
    <MyPage />
  </div>
</template>

<script>
import MyPage from "./components/MyPage.vue";
export default {
  components: {
    MyPage,
  },
};
</script>