<template>
  <div class="body">
    <v-container class="px-md-12 py-8">
      <div class="text-h5">Reflections from Usrah Budi 💖</div>
      <br />
      <div class="text-body-2">
        <p>
          Since the first Usrah Budi, I learned and enjoyed a lot from the
          classes. I gained a lot of new knowledge that could benefit me in the
          future. I treat the classes as a weekly tazkirah session for me.
        </p>
        <p>
          Besides that, I can understand other countries' uniqueness and
          cultures by listening to my classmates' stories. It was exciting to
          share about my country and culture too.
        </p>
        <p>
          Despite the pandemic situation that we can meet face to face, it is
          still an enjoyable session to attend every week. Thank you to all the
          naqibs and my fellow classmates.
        </p>
      </div>
      <br />
    </v-container>

    <!-- <v-parallax height="200" src="@/assets/bgori.jpeg"></v-parallax> -->
  </div>
</template>



<script>
export default {
  name: "Reflections",
};
</script>



<style scoped>
.fa-youtube {
  color: rgb(255, 0, 0);
}

.fa-instagram {
  color: #c13584;
}

.fa-wordpress {
  color: #0073aa;
}

.fa-google-play {
  color: #00ceff;
}
</style>