<template>
  <div class="body">
    <!-- <v-parallax
      height="200"
      src="https://lh3.googleusercontent.com/pw/AM-JKLWjXtkYIkVOPURKgNyqjmysjFdaMaZgTIWVAMDB2ivt-k2qOYocnGEepAuV-0NrEAXlEx64asUZrauy1nSD2_SVEXtoJzM-SFrHZz9So4RkqJyAcgDI-IVU9XNzis_2CKBLlgqZv0G9qV1RubnmWVzbww=w840-h473-no"
    ></v-parallax> -->
    <v-container class="px-md-12 py-8">
      <div class="text-h5">Activities <kbd>CCUB 3621</kbd></div>
      <br />

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Naqib: Br Halil Ibrahim (Section 102)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img src="@/assets/Screenshot 2021-08-25 113901.png"></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            City: Urfa (Turkey)</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vQXTHb-leHnIQuPXllXGmmamgdKc25v6GKA16pxxQAqmqXVEzVq-A6Z3zOt619ySA/embed?start=true&loop=false&delayms=3000"
              frameborder="0"
              width="480"
              height="299"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Role Play: Animosity
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Long poster (might take some time to load):
            <div
              style="
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 222.2222%;
                padding-bottom: 48px;
                box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
                margin-top: 1.6em;
                margin-bottom: 0.9em;
                overflow: hidden;
                border-radius: 8px;
                will-change: transform;
              "
            >
              <iframe
                loading="lazy"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  border: none;
                  padding: 0;
                  margin: 0;
                "
                src="https://www.canva.com/design/DAEm_ox9KNE/view?embed"
              >
              </iframe>
            </div>
            <a
              href="https://www.canva.com/design/DAEm_ox9KNE/view?utm_content=DAEm_ox9KNE&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link"
              target="_blank"
              rel="noopener"
              >Animosity (Task 2: Role Play)</a
            >
            by MUHD FAREEZ IQMAL
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Activities3",
};
</script>

<style scoped>
</style>