<template>
  <div class="body">
    <!-- <v-parallax
      height="200"
      src="https://lh3.googleusercontent.com/pw/AM-JKLWjXtkYIkVOPURKgNyqjmysjFdaMaZgTIWVAMDB2ivt-k2qOYocnGEepAuV-0NrEAXlEx64asUZrauy1nSD2_SVEXtoJzM-SFrHZz9So4RkqJyAcgDI-IVU9XNzis_2CKBLlgqZv0G9qV1RubnmWVzbww=w840-h473-no"
    ></v-parallax> -->
    <v-container class="px-md-12 py-8">
      <div class="text-h5">Activities <kbd>CCUB 1621</kbd></div>
      <br />

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Naqib: Br Zulfakar Aziz (Section 24)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img src="@/assets/Screenshot 2021-08-25 113901.png"></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Taaruf with the X and the Y</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <iframe
              src="https://drive.google.com/file/d/1GhSZ60tGc6J-nKQqysII5RHvx8wKl85s/preview"
              frameborder="0"
              width="480"
              height="299"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Education of Islamic History
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <iframe
              src="https://drive.google.com/file/d/1Gfh7iGEbHMu59GnoaI7_CnPSBt3o2fcr/preview"
              frameborder="0"
              width="480"
              height="299"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Activities1",
};
</script>

<style scoped>
</style>