<template>
  <v-container class="pa-12">
    <div class="text-caption text-center">
      Thank you for visiting. Website made with
      <a href="https://vuejs.org/">Vue.js</a>,
      <a href="https://vuetifyjs.com/" target="_blank" rel="noopener noreferrer"
        >Vuetify</a
      >. Icons are from
      <a
        href="http://fontawesome.com/"
        target="_blank"
        rel="noopener noreferrer"
        >FontAwesome</a
      >. Build & hosted on
      <a
        href="https://www.netlify.com/"
        target="_blank"
        rel="noopener noreferrer"
        >Netlify</a
      >.
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>